import LayoutProductPage from '../../components/layouts/ProductPage';
import * as styleProduct from '../../styles/pages/productIndex.module.scss';
import * as styleOperationPage from '../../styles/pages/operationPage.module.scss';
import * as gstyle from '../../styles/styles.module.scss';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';
import React from 'react';
import classnames from 'classnames';
import { OperationSupportPagedata } from '../../data/page-operationSupport.data';
import { Accordion } from '../../data/accordion.data';

export default function operationPage() {
  const data = OperationSupportPagedata();
  let page_content = data?.content;
  const dateStr = data.modified;
  const dateObj = new Date(dateStr);
  const friendlyDate = dateObj.toLocaleString(undefined, {
    dateStyle: 'short',
    timeStyle: 'short',
  });

  page_content = new Accordion(page_content).get();

  return (
    <section>
      <Helmet title="TALXIS Provozní podpora"> </Helmet>
      <LayoutProductPage
        header="TALXIS"
        copy="Představte si informační systém, který si poskládáte podle vlastních představ a upravíte na míru přímo Vašim potřebám."
        subHeader="Provozní podpora"
        color="purple"
        backLink="/products"
      >
        <section className={styleOperationPage.section} style={styleOperationPage}>
          <div className={classnames(gstyle.center, gstyle.modified)}>
            Poslední úprava {friendlyDate}
          </div>

          <div
            className={classnames(gstyle.center, styleOperationPage.content)}
            dangerouslySetInnerHTML={{ __html: page_content }}
          ></div>
        </section>
      </LayoutProductPage>
      <section className={styleProduct.footerSection}>
        <Footer></Footer>
      </section>
    </section>
  );
}
