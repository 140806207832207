import { I18nextContext } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useOperativeSupportPageMetaDataCs } from '../hooks/cs/page-operationalSupport.metadata';
import { useOperativeSupportPageMetaDataEn } from '../hooks/en/page-operationSupport.metadata';

export function OperationSupportPagedata(): any {
  const { language } = React.useContext(I18nextContext);
  let data;
  if (language == 'cs') {
    data = useOperativeSupportPageMetaDataCs();
  } else {
    data = useOperativeSupportPageMetaDataEn();
  }
  return data;
}
