import { useStaticQuery, graphql } from 'gatsby';
export const useOperativeSupportPageMetaDataEn = () => {
  const site = useStaticQuery(graphql`
    query OperationSupportPageEn {
      wpPage(id: { eq: "cG9zdDoxNzI4" }) {
        id
        title
        content
      }
    }
  `);
  return site?.wpPage;
};
